new WOW({ offset: 70 }).init();

$(document).ready(function () {

	$('.textContent a').addClass("has-tooltip");

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$(".toplinks__dropdown").each(function () {
		$(".toplinks__dropdown-link", this).each(function (index, value) {
			$(this).attr({ "data-wow-delay": index / 10 + "s", "data-wow-offset": 0 })
				.addClass("wow fadeIn");
		});
	});

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		$("#TopLinksInner .dropdown-menu a").addClass("animated");
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});


	if ($('#HomepageMarker').length > 0) {

		// this removes the '/' between testimonial author & date if there are any testimonials
		// its difficult to style otherwise & is rarely wanted.
		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']")[0].nextSibling.nodeValue = '';
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}

		// this is handy for staggered animations...
		// $('.many').each(function (i, el) {
		//	$(el).addClass('wow fadeInUp2').attr('data-wow-delay', i / 5 + 's').attr('data-wow-offset', '150');
		// });

		$('.header__logo').addClass("wow fadeInDown");
	} else {
		$('.header__logo').css('visibility', 'visible');
	}

	function fixHeights() {
		$('.RotatorTestimonial').sameHeight();
		$('.featured-categories__title').sameHeight();
	}

	setTimeout(fixHeights, 300);


	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});


	$('.testimonials .carousel-inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 2,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
});

